import type { FC } from 'react'
import React from 'react'
import { Box, BoxProps, Icon, StackProps, Text } from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import InputPlaceholder from 'ui/common/InputPlaceholder'

interface SearchPlaceholderProps extends BoxProps {
  title: string
  inputPlaceholderProps?: StackProps
}

const SearchPlaceholder: FC<SearchPlaceholderProps> = ({
  title,
  inputPlaceholderProps,
  ...props
}) => {
  return (
    <Box fontSize="md" transition="all 0.25s ease-in" w="full" {...props}>
      <InputPlaceholder {...inputPlaceholderProps}>
        <Text color="text.secondary">{title}</Text>
        <Icon as={FiSearch} boxSize={4} color="text.default" />
      </InputPlaceholder>
    </Box>
  )
}

export default SearchPlaceholder
