import React from 'react'
import { OrderDTO, OrderStatus } from '@gourmate/api'
import { Badge } from '@chakra-ui/react'

interface OrderStatusBadgeProps {
  order: OrderDTO
}

export const StatusBadge = ({ order }: OrderStatusBadgeProps) => {
  switch (order.status) {
    case OrderStatus.Canceled:
      return <Badge colorScheme="red">Canceled</Badge>

    case OrderStatus.Created:
      return <Badge colorScheme="orange">Awaiting buyer</Badge>

    case OrderStatus.AwaitingApproval:
      return <Badge colorScheme="yellow">Awaiting chef</Badge>

    case OrderStatus.AwaitingPayment:
      return <Badge colorScheme="orange">Awaiting payment</Badge>

    case OrderStatus.Complete:
      return <Badge colorScheme="green">Completed</Badge>

    case OrderStatus.AwaitingPickup:
      return <Badge colorScheme="green">Awaiting pickup</Badge>

    default:
      return null
  }
}
