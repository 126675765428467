import { FlexProps, Heading, StackProps, Text, VStack } from '@chakra-ui/react'
import { AppLink, GrandAvatar, LoadAnimation, Rating } from 'ui'
import { AccountDTO } from '@gourmate/api'
import AspectRatio from 'ui/common/AspectRatio'
import Jelly from 'ui/animated/Jelly'
import React from 'react'
import { IMAGE_QUALITY_HIGH } from '#/src/app/(application)/_constants'

type SupplierListItemProps = StackProps & {
  item: AccountDTO
}

export const SupplierListItem = ({ item, ...props }: SupplierListItemProps) => {
  return (
    <VStack
      _hover={{
        textDecoration: 'none'
      }}
      as={AppLink}
      href={`/app/users/${item.id}`}
      {...props}>
      <LoadAnimation viewport={{ once: false }}>
        <Jelly>
          <AspectRatio<FlexProps, 'w'>
            breakpoints={{
              base: '1:1'
            }}
            sx={{
              '.children-container': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }
            }}
            {...props}>
            <GrandAvatar
              h="90%"
              imageProps={{
                src: item.thumb,
                alt: item.firstName,
                quality: IMAGE_QUALITY_HIGH
              }}
              shadow="xs"
              w="90%"
            />
          </AspectRatio>
        </Jelly>
      </LoadAnimation>

      <VStack alignItems="stretch" gap={1} w="full">
        <Heading as="h4" size="xs">
          {item.firstName} {item.lastName}
        </Heading>

        {item.rating ? (
          <Rating avgRating={item.rating.avgRating} nRatings={item.rating.nRatings} />
        ) : null}

        <Text
          _dark={{ color: 'gray.300' }}
          color="gray.600"
          fontSize="md"
          fontWeight="normal"
          isTruncated>
          {item.description}
        </Text>
      </VStack>
    </VStack>
  )
}
