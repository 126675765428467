import { useSelector } from 'react-redux'
import { selectAccountState } from '#/src/state/account/slices/accountSlice'
import { AccountType } from '@gourmate/api'
import { IconButton } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { useRouter } from 'next/navigation'

export const CreateOfferButton = () => {
  const router = useRouter()
  const { currentAccount } = useSelector(selectAccountState)
  const isChef = currentAccount?.type === AccountType.Supplier

  if (!isChef) {
    return null
  }

  return (
    <IconButton
      aria-label="Create an offer"
      position="fixed"
      bottom="26"
      right="4"
      zIndex={1}
      borderRadius="50%"
      size="lg"
      fontSize="2xl"
      boxShadow="md"
      onClick={() => {
        router.push('/app/supplier/create-offer')
      }}>
      <MdAdd />
    </IconButton>
  )
}
