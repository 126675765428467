import {
  Box,
  BoxProps,
  FlexProps,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
  Wrap
} from '@chakra-ui/react'
import { AppLink, GrandAvatar, ImageBox, LoadAnimation, Price, Rating } from 'ui'
import { OfferLightDTO, PositionDTO, toReadableTimings } from '@gourmate/api'
import { FiBox } from 'react-icons/fi'
import AspectRatio from 'ui/common/AspectRatio'
import { GrLocation } from 'react-icons/gr'
import Jelly from 'ui/animated/Jelly'
import React from 'react'
import Timings from '#/src/app/(application)/app/_components/ListItems/Timings'
import { IMAGE_QUALITY_HIGH } from '#/src/app/(application)/_constants'

type OfferListItemProps = BoxProps & {
  item: OfferLightDTO
  distance?: number
  itemPos?: PositionDTO
}

export const OfferListItem = ({ item, distance, itemPos, ...props }: OfferListItemProps) => {
  const timings = itemPos?.timings && toReadableTimings(itemPos.timings)

  return (
    <Box
      _hover={{
        textDecoration: 'none'
      }}
      as={AppLink}
      bg="background.default"
      href={`/app/offers/${item.id}`}
      rounded="xl"
      {...props}>
      <LoadAnimation viewport={{ once: false }}>
        <Jelly>
          <AspectRatio<FlexProps, 'w'>
            breakpoints={{
              base: '1:1'
            }}
            {...props}>
            <ImageBox
              containerProps={{
                bg: 'gray.300',
                overflow: 'hidden',
                borderTopRadius: 'md'
              }}
              h="full"
              imageProps={{
                src: `${item.thumb}`,
                alt: item.name,
                style: { objectFit: 'cover' },
                quality: IMAGE_QUALITY_HIGH
              }}
              overflow="hidden"
              rounded="xl"
              shadow="xs"
              w="full"
            />
          </AspectRatio>
        </Jelly>
      </LoadAnimation>

      <VStack alignItems="stretch" gap={2} py={2} w="full">
        <HStack justify="space-between" w="full">
          <Heading as="h6" fontSize="md" fontWeight="bold" isTruncated>
            {item.name}
          </Heading>
        </HStack>

        {item.rating ? (
          <Rating avgRating={item.rating.avgRating} nRatings={item.rating.nRatings} />
        ) : null}
        <HStack>
          <GrandAvatar
            h="5vw"
            imageProps={{
              src: item.accountThumb,
              objectFit: 'cover',
              sizes: '5vw',
              quality: IMAGE_QUALITY_HIGH
            }}
            w="5vw"
          />
          <Text
            _dark={{ color: 'gray.300' }}
            color="gray.600"
            fontSize="md"
            fontWeight="normal"
            isTruncated>
            {item.accountFullName}
          </Text>
        </HStack>

        <Wrap fontSize="sm" gap={4} w="full">
          {distance ? (
            <HStack>
              <Icon _dark={{ color: 'primary.600' }} as={GrLocation} color="primary.900" />
              <Text color="text.secondary">{(distance / 1000).toFixed(2)}km</Text>
            </HStack>
          ) : null}
          <HStack>
            <Icon _dark={{ color: 'primary.600' }} as={FiBox} color="primary.900" />
            <Text color="text.secondary" textAlign="right">
              {item.stock}/{item.originalStock} st.
            </Text>
          </HStack>
          {timings ? <Timings timings={timings} flexWrap="wrap" /> : null}
        </Wrap>

        <HStack justify="space-between" w="full">
          <Price currency="SEK" fontSize="md" fontWeight="bold" price={item.price} />
        </HStack>
      </VStack>
    </Box>
  )
}
