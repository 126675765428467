import React, { FC, useCallback } from 'react'
import { MyOrdersOp, OrderDTO } from '@gourmate/api'
import { Box, Heading, HStack, StackDivider, Text, VStack } from '@chakra-ui/react'
import { ScrollDragContainer, UICountdown } from 'ui'
import { isOrderExpirable } from '#/src/utils'
import { StatusBadge } from '#/src/app/(application)/app/_components/OrderStatusBadge'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'

interface ActiveOrdersProps {
  orders: OrderDTO[] | null
  title: string
  op: MyOrdersOp
  onCountdownComplete?: (order: OrderDTO) => void
}

const ActiveOrders: FC<ActiveOrdersProps> = ({
  orders,
  title,
  op,
  onCountdownComplete,
  ...props
}) => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const onClick = useCallback(
    (orderId: string) => {
      const searchParamsStr = searchParams.toString()
      let fullPath = searchParamsStr ? `${pathname}?${searchParamsStr}` : pathname

      fullPath = encodeURIComponent(fullPath)

      const queryParams = new URLSearchParams()
      queryParams.set('prev', fullPath)

      router.push(`/app/orders/${orderId}?${queryParams.toString()}`)
    },
    [pathname, router, searchParams]
  )

  if (!orders || !orders.length) {
    return null
  }

  return (
    <VStack alignItems="flex-start" gap={1} {...props}>
      <Heading px={6} variant="secondary">
        {title}
      </Heading>

      <Box overflow="hidden" w="100vw">
        <ScrollDragContainer pl={6} py={4}>
          <HStack
            _after={{
              content: '""',
              minW: 2,
              display: 'block',
              height: 1
            }}
            alignItems="stretch"
            className="item-container"
            divider={<StackDivider />}
            gap={2}
            w="100vw">
            {orders.map((order) => {
              return (
                <VStack
                  borderColor="border"
                  borderWidth={1}
                  key={order.id}
                  onClick={() => onClick(order.id)}
                  px={4}
                  py={2}
                  rounded="md"
                  shadow="md">
                  <HStack w="full">
                    <Text flex={1} fontSize="md" isTruncated>
                      {order.offerName}{' '}
                    </Text>
                    <Text as="span" color="text.secondary" flex={0} fontSize="xs">
                      x{order.qty}
                    </Text>
                  </HStack>

                  <StatusBadge {...{ order }} />

                  <Controls {...{ op, order }} />
                  {isOrderExpirable(order) ? (
                    <Text as="span" color="red.400" fontSize="sm" fontWeight="bold">
                      <UICountdown
                        date={new Date(order.expiresAt)}
                        onComplete={() => onCountdownComplete?.(order)}
                      />
                    </Text>
                  ) : null}
                </VStack>
              )
            })}
          </HStack>
        </ScrollDragContainer>
      </Box>
    </VStack>
  )
}

export default ActiveOrders

// todo controls can be added to the homescreen
const Controls = ({ op }: { op: MyOrdersOp; order: OrderDTO }) => {
  switch (op) {
    case MyOrdersOp.AsBuyer: {
      return null
    }

    case MyOrdersOp.AsSeller: {
      return null
    }

    default:
      return null
  }
}
