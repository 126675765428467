import { ScrollDragContainer } from 'ui'
import { Box, Heading, HStack, StackProps, Text, VStack } from '@chakra-ui/react'
import { ListItemSkeleton } from 'src/app/(application)/app/_components/ListItems'
import React, { Fragment, ReactElement, ReactNode } from 'react'

type OffersListProps<T> = StackProps & {
  items?: T[]
  isLoading?: boolean
  title?: ReactNode
  itemRenderer: (item: T) => ReactElement | null
}

export const ItemsList = <T,>({
  items,
  title,
  isLoading,
  itemRenderer,
  ...props
}: OffersListProps<T>) => {
  return (
    <VStack alignItems="flex-start" gap={1} {...props}>
      {!!title && (
        <Heading px={6} variant="secondary">
          {title}
        </Heading>
      )}

      <Box overflow="hidden" w="100vw">
        <ScrollDragContainer pl={6} py={4}>
          <HStack
            _after={{
              content: '""',
              minW: 2,
              display: 'block',
              height: 1
            }}
            alignItems="stretch"
            className="item-container"
            gap={4}
            w="100vw">
            {!isLoading && items && !items.length ? (
              <Box alignSelf="center" mx="auto" w="80%">
                <Text color="text.secondary" textAlign="center">
                  Sorry, we didn&apos;t find anything.
                </Text>
              </Box>
            ) : null}

            {isLoading
              ? new Array(3)
                  .fill(null)
                  .map((_, index) => <ListItemSkeleton className="item-skeleton" key={index} />)
              : items?.map((item, index) => <Fragment key={index}>{itemRenderer(item)}</Fragment>)}
          </HStack>
        </ScrollDragContainer>
      </Box>
    </VStack>
  )
}
