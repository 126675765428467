import type { FC } from 'react'
import { HStack, IconButton, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { LocalStoragePlace } from '#/src/lib/Locations'
import { CgRemove } from 'react-icons/cg'

interface LocationItemProps {
  onSelect: (value: string) => void
  onRemove: (place: LocalStoragePlace) => void
  value: string | undefined
  place: LocalStoragePlace
}

export const LocationItem: FC<LocationItemProps> = ({ onRemove, onSelect, value, place }) => {
  return (
    <HStack align="center" gap={4} minH={10} pr={place.isDefault ? 14 : 0} py={0} w="full">
      <RadioGroup display="flex" flex={1} onChange={onSelect} {...{ value }}>
        <Radio value={place.id} w="100%">
          <Text as="span" fontSize="sm">
            {place.formattedAddress}
          </Text>
        </Radio>
      </RadioGroup>

      {!place.isDefault && (
        <IconButton
          aria-label="remove"
          color="red.300"
          icon={<CgRemove />}
          onClick={() => {
            onRemove(place)
          }}
          rounded="full"
          size="md"
          variant="ghost"
        />
      )}
    </HStack>
  )
}

export default LocationItem
