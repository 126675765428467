import { HStack, type StackProps } from '@chakra-ui/react'
import { useWindowOnTop } from '../../hooks'

type NavTopBackdropProps = StackProps

export const NavTopBackdrop = ({ children, ...props }: NavTopBackdropProps) => {
  const { isWindowOnTop } = useWindowOnTop()

  return (
    <HStack
      backdropFilter="blur(12px)"
      justify="space-between"
      p={2}
      top={0}
      position="fixed"
      shadow={isWindowOnTop ? 'none' : 'xs'}
      spacing={0}
      transition="all 0.75s cubic-bezier(.33,1,.68,1) .3s"
      w="full"
      zIndex="modal"
      {...props}>
      {children}
    </HStack>
  )
}
