'use client'
import {
  FetchError,
  MyOrdersParams,
  order,
  OrderCancellationCreateParams,
  OrderCompleteParams,
  OrderCreateParams,
  OrderIdentifier
} from '@gourmate/api'

export const myOrders = async (params: MyOrdersParams) => {
  const endpoint = order.myOrders(params, { init: { cache: 'no-store' } })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}

export const createOrder = async (
  params: OrderCreateParams,
  onRefreshFail: () => Promise<void>
) => {
  const endpoint = order.create(params, { init: { cache: 'no-store' }, onRefreshFail })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}

export const showOrder = async (params: OrderIdentifier) => {
  const endpoint = order.show(params, {
    init: { next: { revalidate: 0 } }
  })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}

export const approveOrder = async (params: OrderIdentifier) => {
  const endpoint = order.approve(params, {
    init: { next: { revalidate: 0 } }
  })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}

export const completeOrder = async (params: OrderIdentifier & OrderCompleteParams) => {
  const endpoint = order.complete(params, {
    init: { next: { revalidate: 0 } }
  })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}

export const cancelOrder = async (params: OrderIdentifier & OrderCancellationCreateParams) => {
  const endpoint = order.cancel(params, {
    init: { next: { revalidate: 0 } }
  })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}

export const showCancellation = async (params: OrderIdentifier) => {
  const endpoint = order.showCancellation(params, {
    init: { cache: 'no-store' }
  })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}

export const orderPickupPin = async (params: OrderIdentifier) => {
  const endpoint = order.pickupPin(params, {
    init: { next: { revalidate: 0 } }
  })
  const response = await endpoint.fetch()

  if (response instanceof FetchError) {
    return response.data
  }

  return response
}
