import { Box, BoxProps, Heading, Text, VStack } from '@chakra-ui/react'
import { CategoryDTO } from '@gourmate/api'
import Jelly from 'ui/animated/Jelly'
import LoadAnimation from 'ui/animated/LoadAnimation'
import { AppLink } from 'ui'

type CategoryListItem = BoxProps & {
  item: CategoryDTO
}

export const CategoryListItem = ({ item, ...props }: CategoryListItem) => {
  return (
    <Box
      _hover={{
        textDecoration: 'none'
      }}
      as={AppLink}
      bg="background.default"
      href={`/app/explore?category=${item.id}`}
      rounded="xl"
      {...props}>
      <VStack alignItems="center" gap={2} py={2} w="full">
        <LoadAnimation viewport={{ once: false }}>
          <Jelly>
            <Heading as="h6" fontSize="md" fontWeight="bold" isTruncated>
              {item.type}
            </Heading>
          </Jelly>
        </LoadAnimation>

        <Text color="text.secondary" fontSize="sm" fontWeight="normal" isTruncated>
          {item.country}
        </Text>
      </VStack>
    </Box>
  )
}
