import type { FC } from 'react'
import React from 'react'
import { HStack, Icon, StackProps, Text } from '@chakra-ui/react'
import { FaRegClock } from 'react-icons/fa6'
import { HiMiniCalendarDays } from 'react-icons/hi2'
import { PositionReadableTimings } from '@gourmate/api'
import { getReadableWeekdays } from '#/src/utils'

interface TimingsProps extends StackProps {
  timings: PositionReadableTimings
}

const Timings: FC<TimingsProps> = ({ timings, ...props }) => {
  return (
    <HStack {...props}>
      <HStack>
        <Icon _dark={{ color: 'primary.600' }} as={FaRegClock} color="primary.900" />
        <Text color="text.secondary" textAlign="right">
          {timings.from} - {timings.to}
        </Text>
      </HStack>
      <HStack flex={1} w="full">
        <Icon _dark={{ color: 'primary.600' }} as={HiMiniCalendarDays} color="primary.900" />
        <Text color="text.secondary" textAlign="right">
          {getReadableWeekdays(timings.weekdays, { isShortNames: true, isGrouped: true })}
        </Text>
      </HStack>
    </HStack>
  )
}

export default Timings
