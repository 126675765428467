import React, { FC, useState } from 'react'
import { Divider, Heading, RadioGroup, StackDivider, VStack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDeviceLocationState,
  setCreateLocation,
  setDefaultLocation,
  setRemoveLocation
} from '#/src/state/device-loc/slices/deviceLocSlice'
import LocationItem from '#/src/app/(application)/app/_components/LocationPicker/LocationItem'
import { NavSection } from 'ui/layout'
import { AddressEditable } from '#/src/app/(application)/_components/AddressEditable'

interface LocationMainViewProps {
  onClose: () => void
}

const LocationMainView: FC<LocationMainViewProps> = ({ onClose }) => {
  const [selectedPosition, setSelectedPosition] = useState('1')
  const dispatch = useDispatch()
  const { locations, defaultLoc } = useSelector(selectDeviceLocationState)

  const handleLocationSelect = (v: string) => {
    dispatch(setDefaultLocation({ id: v }))
    onClose()
  }

  return (
    <VStack gap={8} px={6} w="full">
      <VStack w="full">
        <Heading flex={1} mx={-6} variant="main">
          Add or choose a location
        </Heading>
        <Divider />
      </VStack>

      <AddressEditable
        onConfirm={(payload) => {
          dispatch(setCreateLocation({ loc: payload, isDefault: true }))
          onClose()
        }}
      />

      <RadioGroup onChange={setSelectedPosition} value={selectedPosition} w="full">
        <VStack divider={<StackDivider />} spacing={4} w="full">
          <NavSection
            itemRenderer={(item) => (
              <LocationItem
                onRemove={(p) => {
                  dispatch(setRemoveLocation({ loc: p }))
                }}
                onSelect={handleLocationSelect}
                place={item}
                value={defaultLoc?.id}
              />
            )}
            items={locations}
            title="Locations"
          />
        </VStack>
      </RadioGroup>
    </VStack>
  )
}

export default LocationMainView
