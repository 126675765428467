import { Skeleton, SkeletonProps, SkeletonText, VStack } from '@chakra-ui/react'

export const ListItemSkeleton = (props: SkeletonProps) => {
  return (
    <VStack {...props}>
      <Skeleton flex={1} minW="full" rounded="lg" />
      <SkeletonText flex={1} minW="full" mt="4" noOfLines={4} skeletonHeight="3" spacing="4" />
    </VStack>
  )
}
