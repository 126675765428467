import type { FC } from 'react'
import React, { useCallback, useEffect } from 'react'
import { Badge, Box, Icon, IconButton } from '@chakra-ui/react'
import { AppLink } from 'ui'
import { MdNotifications } from 'react-icons/md'
import { usePaginate } from '#/src/hooks/usePaginate'
import { NotificationDTO } from '@gourmate/api'
import { myNotifications } from '#/src/api/client/notifications-client'

interface NotificationsCounterProps {}

const NotificationsCounter: FC<NotificationsCounterProps> = () => {
  const { fetch, data } = usePaginate<NotificationDTO>()

  const fetchData = useCallback(async () => {
    return fetch(() =>
      myNotifications({
        page: 1,
        size: 10000,
        isRead: false
      })
    )
  }, [fetch])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  return (
    <Box position="fixed" right={2} top={2} zIndex="modal">
      {data?.length ? (
        <Badge
          alignItems="center"
          bg="secondary.500"
          color="white"
          colorScheme="secondary"
          display="flex"
          fontSize="2xs"
          justifyContent="center"
          position="absolute"
          rounded="full"
          right="-0.1rem"
          top="-0.1rem"
          w="1.2rem"
          h="1.2rem"
          zIndex="modal">
          {data.length}
        </Badge>
      ) : null}

      <IconButton
        aria-label="notifications"
        as={AppLink}
        href="/app/notifications"
        icon={<Icon as={MdNotifications} boxSize={6} color="primary.500" />}
        size="sm"
        variant="ghost"
      />
    </Box>
  )
}

export default NotificationsCounter
