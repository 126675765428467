'use client'

import {
  FetchError,
  notification,
  NotificationFilterParams,
  NotificationIdentifier,
  NotificationUpdateParams,
  PaginatePayload
} from '@gourmate/api'

export const myNotifications = async (params: PaginatePayload<NotificationFilterParams>) => {
  const endpoint = notification.filter(params, { init: { cache: 'no-store' } })
  const res = await endpoint.fetch()

  if (res instanceof FetchError) {
    return res.data
  }

  return res
}

export const updateNotification = async (
  params: NotificationUpdateParams & NotificationIdentifier
) => {
  const endpoint = notification.update(params, { init: { cache: 'no-store' } })
  const res = await endpoint.fetch()

  if (res instanceof FetchError) {
    return res.data
  }

  return res
}
