import { useCallback, useState } from 'react'
import { ErrorItem, ErrorResponse } from '@gourmate/api'

export const useReq = <T extends Object>() => {
  const [error, setError] = useState<ErrorItem[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<T | null>(null)

  const fetch = useCallback(async (fetcher: () => Promise<ErrorResponse | T>) => {
    setIsLoading(true)
    const result = await fetcher()

    if ('errors' in result) {
      setError(result.errors)
      setIsLoading(false)
      return
    }
    setError(undefined)
    setData(result)
    setIsLoading(false)

    return result
  }, [])

  const reset = useCallback(() => {
    setError(undefined)
    setData(null)
  }, [])

  return {
    fetch,
    data,
    reset,
    error,
    isLoading
  }
}
