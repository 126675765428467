'use client'
import { HStack, Icon, Text, useDisclosure } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { LocalStoragePlace } from '#/src/lib/Locations'
import { useSelector } from 'react-redux'
import { selectDeviceLocationState } from '#/src/state/device-loc'
import { BottomSheet, BottomSheetProps } from 'ui'
import { TiArrowSortedDown } from 'react-icons/ti'
import LocationMainView from './LocationMainView'

interface LocationPickerProps extends Partial<BottomSheetProps> {}

export const LocationPicker: FC<LocationPickerProps> = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentLocation, setCurrentLocation] = useState<LocalStoragePlace | null>(null)
  const { defaultLoc } = useSelector(selectDeviceLocationState)

  useEffect(() => {
    if (defaultLoc) {
      setCurrentLocation(defaultLoc)
    }
  }, [defaultLoc])

  return (
    <BottomSheet
      sheetProps={{
        snapPoints: [0.95],
        initialSnap: 0
      }}
      target={
        <HStack
          className="location-picker-target"
          color="secondary.500"
          fontSize="md"
          maxW="80vw"
          onClick={onOpen}>
          <Text isTruncated userSelect="none">
            {currentLocation?.formattedAddress || 'Select location'}
          </Text>
          <Icon as={TiArrowSortedDown} />
        </HStack>
      }
      {...{ isOpen, onClose }}
      {...props}>
      <LocationMainView {...{ onClose }} />
    </BottomSheet>
  )
}
