'use client'
import React, { useCallback, useEffect, useMemo } from 'react'
import { ItemsList } from '#/src/app/(application)/app/_components/ItemsList'
import { usePaginate } from '#/src/hooks/usePaginate'
import { accountsFilter, offerFilter, offerNearMeFilter } from '#/src/api'
import {
  AccountDTO,
  AccountType,
  CategoryDTO,
  MyOrdersOp,
  NearMeParams,
  OfferFilterParams,
  OfferLightDTO,
  OffersNearMe,
  OfferStatus,
  ongoingOrderStatus,
  OrderDTO,
  PaginatePayload,
  SortOrder
} from '@gourmate/api'
import { OfferListItem } from '#/src/app/(application)/app/_components/ListItems/OfferListItem'
import { categoriesFilter } from '#/src/api/categories'
import { CategoryListItem } from '#/src/app/(application)/app/_components/ListItems/CategoryListItem'
import { SupplierListItem } from '#/src/app/(application)/app/_components/ListItems/SupplierListItem'
import SearchPlaceholder from '#/src/app/(application)/app/_components/SearchPlaceholder'
import { DEFAULT_LOCATION } from '#/src/constants/locations'
import { LocationPicker } from '#/src/app/(application)/app/_components/LocationPicker/LocationPicker'
import { useSelector } from 'react-redux'
import { selectDeviceLocationState } from '#/src/state/device-loc/slices/deviceLocSlice'
import { useRouter } from 'next/navigation'
import { getWeekDay } from 'shared-utils'
import { useWindowOnTop } from 'ui/hooks'
import { NavLayout } from 'ui/layout'
import { InnerPageWrapper } from '#/src/app/(application)/_components/InnerPageWrapper'
import NotificationsCounter from '#/src/app/(application)/app/_components/NotificationsCounter'
import { useReq } from '#/src/hooks/useReq'
import { myOrders } from '#/src/api/client/orders-client'
import ActiveOrders from '#/src/app/(application)/app/_components/ActiveOrders'
import { CreateOfferButton } from '#/src/app/(application)/app/_components/CreateOfferButton'
import { NavTopBackdrop } from 'ui/layout/NavLayout/NavTopBackdrop'
import { Spacer } from '@chakra-ui/react'

export default function Main() {
  const { isWindowOnTop } = useWindowOnTop()
  const { defaultLoc } = useSelector(selectDeviceLocationState)
  const router = useRouter()

  const favoriteCategoriesIds = useMemo(() => {
    const ids = process.env.NEXT_PUBLIC_FAVORITE_CATEGORIES
    return ids ? ids.split(',') : []
  }, [])

  const favoriteChefsIds = useMemo(() => {
    const ids = process.env.NEXT_PUBLIC_FAVORITE_CHEFS
    return ids ? ids.split(',') : []
  }, [])

  const {
    fetch: fetchTodaysOffers,
    data: todaysOffers,
    isLoading: isLoadingTodaysOffers
  } = usePaginate<OffersNearMe>()

  const {
    fetch: fetchNearMe,
    data: nearMe,
    isLoading: isLoadingNearMe
  } = usePaginate<OffersNearMe>()

  const {
    fetch: fetchCategories,
    data: categories,
    isLoading: isLoadingCategories
  } = usePaginate<CategoryDTO>()

  const {
    fetch: fetchSupplierAccounts,
    data: supplierAccounts,
    isLoading: isLoadingSupplierAccounts
  } = usePaginate<AccountDTO>()

  const {
    fetch: fetchWalletFriendlyOffers,
    data: walletFriendlyOffers,
    isLoading: isLoadingWalletFriendlyOffers
  } = usePaginate<OfferLightDTO>()

  useEffect(() => {
    if (!favoriteCategoriesIds.length) {
      return
    }

    void fetchCategories(() =>
      categoriesFilter({
        page: 1,
        size: 10,
        ids: favoriteCategoriesIds
      })
    )
  }, [favoriteCategoriesIds, fetchCategories])

  useEffect(() => {
    if (!favoriteChefsIds.length) {
      return
    }

    void fetchSupplierAccounts(() =>
      accountsFilter({
        page: 1,
        size: 10,
        sort: { id: SortOrder.Desc },
        type: AccountType.Supplier,
        ids: favoriteChefsIds
      })
    )
  }, [favoriteChefsIds, fetchSupplierAccounts])

  useEffect(() => {
    void fetchWalletFriendlyOffers(() =>
      offerFilter({
        page: 1,
        size: 10,
        sort: { price: SortOrder.Asc },
        status: OfferStatus.Available,
        priceRange: [0, 120]
      })
    )
    // eslint-disable-next-line
  }, [])

  const fetchNearMeData = useCallback(async () => {
    const payload = {
      page: 1,
      size: 10,
      sort: { distance: SortOrder.Asc },
      status: OfferStatus.Available,
      lat: defaultLoc?.lat || DEFAULT_LOCATION.lat,
      lng: defaultLoc?.lng || DEFAULT_LOCATION.lng,
      maxDistance: 10000
    }
    return fetchNearMe(() => offerNearMeFilter(payload))
  }, [defaultLoc?.lat, defaultLoc?.lng, fetchNearMe])

  useEffect(() => {
    void fetchNearMeData()
  }, [fetchNearMeData])

  const fetchTodaysOffersData = useCallback(async () => {
    const payload: PaginatePayload<OfferFilterParams & NearMeParams> = {
      page: 1,
      size: 10,
      sort: { 'position.timings.from.hour': SortOrder.Asc, distance: SortOrder.Asc },
      status: OfferStatus.Available,
      lat: defaultLoc?.lat || DEFAULT_LOCATION.lat,
      lng: defaultLoc?.lng || DEFAULT_LOCATION.lng,
      maxDistance: 7500,
      timings: {
        weekdays: [getWeekDay(new Date())]
      }
    }
    return fetchTodaysOffers(() => offerNearMeFilter(payload))
  }, [defaultLoc?.lat, defaultLoc?.lng, fetchTodaysOffers])

  useEffect(() => {
    void fetchTodaysOffersData()
  }, [fetchTodaysOffersData])

  const { fetch: fetchOngoingOrdersAsBuyer, data: onGoingOrdersAsBuyer } = useReq<OrderDTO[]>()

  const fetchOngoingOrdersAsBuyerData = useCallback(async () => {
    return fetchOngoingOrdersAsBuyer(() =>
      myOrders({
        op: MyOrdersOp.AsBuyer,
        statuses: ongoingOrderStatus
      })
    )
  }, [fetchOngoingOrdersAsBuyer])

  useEffect(() => {
    void fetchOngoingOrdersAsBuyerData()
  }, [fetchOngoingOrdersAsBuyerData])

  const { fetch: fetchOngoingOrdersAsSeller, data: onGoingOrdersAsSeller } = useReq<OrderDTO[]>()

  const fetchOngoingOrdersAsSellerData = useCallback(async () => {
    return fetchOngoingOrdersAsSeller(() =>
      myOrders({
        op: MyOrdersOp.AsSeller,
        statuses: ongoingOrderStatus
      })
    )
  }, [fetchOngoingOrdersAsSeller])

  useEffect(() => {
    void fetchOngoingOrdersAsSellerData()
  }, [fetchOngoingOrdersAsSellerData])

  return (
    <NavLayout>
      <NavTopBackdrop h={12} />
      <Spacer h="10" />
      <InnerPageWrapper alignItems="center">
        <LocationPicker
          opacity={isWindowOnTop ? 1 : 0}
          position="fixed"
          top={3.5}
          zIndex="modal"
          left={8}
        />

        <SearchPlaceholder
          fontSize={isWindowOnTop ? 'md' : 'sm'}
          onClick={() => router.push('/app/explore')}
          position="sticky"
          px={isWindowOnTop ? 0 : 2}
          py={isWindowOnTop ? 2 : 0}
          title="What do you want today?"
          top={1}
          w={isWindowOnTop ? 'full' : '80%'}
          zIndex="modal"
        />

        <NotificationsCounter />

        <ActiveOrders
          op={MyOrdersOp.AsBuyer}
          orders={onGoingOrdersAsBuyer}
          title="Your purchases"
          onCountdownComplete={() => setTimeout(() => fetchOngoingOrdersAsBuyerData, 3000)}
        />
        <ActiveOrders
          op={MyOrdersOp.AsSeller}
          orders={onGoingOrdersAsSeller}
          title="Active deliveries"
          onCountdownComplete={() => setTimeout(() => fetchOngoingOrdersAsSellerData, 3000)}
        />

        {!!favoriteCategoriesIds.length && (
          <ItemsList
            isLoading={isLoadingCategories}
            itemRenderer={(item) => <CategoryListItem px={2} {...{ item }} />}
            items={categories ?? undefined}
            sx={{
              '.item-container': {
                minH: '60px'
              },
              '.item-skeleton': {
                minW: '30vw',
                h: '60px'
              }
            }}
            title="Popular categories"
          />
        )}

        <ItemsList
          isLoading={isLoadingNearMe}
          itemRenderer={(item) => (
            <OfferListItem
              distance={item.distance}
              item={item.offer}
              itemPos={item.position}
              maxW="60vw"
              minW="60vw"
            />
          )}
          items={nearMe ?? undefined}
          sx={{
            '.item-container': {
              minH: '415px'
            },
            '.item-skeleton': {
              minW: '60vw',
              h: '415px'
            }
          }}
          title="Near you"
        />

        <ItemsList
          isLoading={isLoadingTodaysOffers}
          itemRenderer={(item) => (
            <OfferListItem
              distance={item.distance}
              item={item.offer}
              itemPos={item.position}
              maxW="60vw"
              minW="60vw"
            />
          )}
          items={todaysOffers ?? undefined}
          sx={{
            '.item-container': {
              minH: '415px'
            },
            '.item-skeleton': {
              minW: '60vw',
              h: '415px'
            }
          }}
          title="Today's offers near you"
        />

        {!!favoriteChefsIds.length && (
          <ItemsList
            isLoading={isLoadingSupplierAccounts}
            itemRenderer={(item) => <SupplierListItem maxW="45vw" minW="45vw" {...{ item }} />}
            items={supplierAccounts ?? undefined}
            sx={{
              '.item-container': {
                minH: '205px'
              },
              '.item-skeleton': {
                minW: '45vw',
                h: '205px'
              }
            }}
            title="Best rated chefs"
          />
        )}

        <ItemsList
          isLoading={isLoadingWalletFriendlyOffers}
          itemRenderer={(item) => <OfferListItem maxW="60vw" minW="60vw" {...{ item }} />}
          items={walletFriendlyOffers ?? undefined}
          sx={{
            '.item-container': {
              minH: '415px'
            },
            '.item-skeleton': {
              minW: '60vw',
              h: '415px'
            }
          }}
          title="Wallet friendly"
        />
      </InnerPageWrapper>

      <CreateOfferButton />
    </NavLayout>
  )
}
